<template>
  <div>
    <a-form ref="form" :model="formState" name="form" style="margin-top: 30px;" scrollToFirstError
            @finish="getByVerificationCode">
      <a-row justify="center">
        <a-form-item class="ui-form__item" label="卖品核销码" name="verificationCode" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input ref="codeInput" v-model:value="formState.verificationCode" placeholder="请输入或扫描核销码"/>
        </a-form-item>
        <a-button type="primary" html-type="submit">查询</a-button>
        <a-button style="margin-left: 10px" @click="reset">重置</a-button>
      </a-row>
    </a-form>
    <h4 style="margin-top: 20px;padding-left: 20px;border-left: solid 3px #169bd5;margin-bottom: 10px;font-size: 16px;" v-if="list.length">核销物品</h4>
    <a-spin :spinning="loading">
      <div v-if="list.length">
        <a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="false" :scroll="{ x: 1000 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'goodsType'">
              <a-tag v-if="record.type === 1" color="blue">单品</a-tag>
              <a-tag v-if="record.type === 5" color="green">套餐</a-tag>
            </template>
            <template v-if="column.key == 'skuName'">
              {{ record.skuName || '-' }}
            </template>
            <template v-if="column.key == 'afterSaleStatus'">
              <div v-if="record.afterSaleStatus === 0">无售后</div>
              <div v-if="record.afterSaleStatus === 1">售后中</div>
              <div v-if="record.afterSaleStatus === 2">完成售后</div>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

    <h4 style="margin-top: 20px;padding-left: 20px;border-left: solid 3px #169bd5;margin-bottom: 10px;font-size: 16px;" v-if="infoList.length">核销信息</h4>
    <a-spin :spinning="loading">
      <div v-if="infoList.length">
        <a-table style="margin-top: 10px;" :columns="infoColumns" :dataSource="infoList" rowKey="id" :pagination="false" :scroll="{ x: 1000 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'userInfo'">
              <div>用户名称：{{record.orderVerifier?.nickName || '-'}} </div>
              <div>联系电话：{{record.orderVerifier?.userPhone || '-'}} </div>
            </template>
            <template v-if="column.key == 'status'">
              <div v-if="record.status === 'WAIT_CHECK'">未核销</div>
              <div v-if="record.status === 'TRADE_FINISH'" style="color: red;font-size: 16px;">已核销</div>
              <div v-if="record.status === 'AFTERSALE_FINISH'">已退款</div>
            </template>
            <template v-if="column.key == 'signTime'">
				<div style="color: red;font-size: 16px;">
					{{ transDateTime(record.signTime) }}
				</div>
            </template>
            <template v-else-if="column.key === 'action'">
              <div v-permission="['verify_sale_manage_snack_confirm']" @click="onVerification(record.id)" v-if="record.deliveryType === 2 && record.status === 'WAIT_CHECK'">
                <!-- 需要二次确认 -->
                <a>核销</a>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {getByVerificationCode, verificationOrderBase} from "@/service/modules/order";
export default {
  components: {},
  data() {
    return {
      loading: false,
      formState: {},
      searchData: [],
      list: [],
      infoList: [],
      infoColumns: [{
        title: '核销码',
        dataIndex: 'verificationCode'
      }, {
        title: '用户信息',
        key: 'userInfo'
      }, {
        title: '核销状态',
        key: 'status'
      }, {
        title: '核销时间',
        key: 'signTime'
      }, {
        title: '核销人',
        dataIndex: 'verificationUserName'
      }, {
        title: '操作',
        key: 'action',
        width: 150,
        align: 'center',
        fixed: 'right'
      }],
      columns: [{
        title: '卖品名称',
        dataIndex: 'goodsTitle'
      }, {
        title: '卖品类型',
        key: 'goodsType'
      }, {
        title: '规格',
        key: 'skuName'
      }, {
        title: '所属影院',
        dataIndex: 'storeName',
      }, {
        title: '购买数量',
        dataIndex: 'num',
      }, {
        title: '销售价格',
        dataIndex: 'price',
      }, {
        title: '售后状态',
        key: 'afterSaleStatus',
      }],
    }
  },
  created() {},
  methods: {
    async getByVerificationCode() {
      this.loading = true;
      try {
        let ret = await getByVerificationCode({
          verificationCode: this.formState.verificationCode,
          saleType: 2
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.orderItemList;
          this.infoList = [];
          this.infoList.push(ret.data);
        }
        if(ret.data.orderVerifier) {
          this.list = this.list.map(item => {
            return {
              ...item,
              storeName: ret.data.orderVerifier.storeName,
            }
          })
        }
      } catch(e) {
        this.loading = false;
      }
    },
    onVerification(orderId) {
      this.$confirm({
        title: '提示',
        content: '确定完成核销吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let res = await verificationOrderBase({
              orderId,
            });
            this.loading = false;
            if (res.code === 200) {
              this.$message.success('核销成功');
              this.getByVerificationCode();
            }
          } catch (error) {
            this.loading = false;
          }
        }
      })
    },
    reset() {
      this.$refs.form.resetFields();
      this.list = [];
      this.infoList = [];
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  width: 500px;
  margin-right: 30px;
}
</style>
